import { Alert, Container } from "@mantine/core";
import React from "react";

export const Error404: React.FC<{}> = () => {
  return (
    <Container>
      <Alert title="Keine Berechtigung" variant="filled" color="red">
        Achtung: Der Stundenzettel kann nur über die MARcloud aufgerufen werden.
      </Alert>
    </Container>
  );
};
