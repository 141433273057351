import {
  ActionIcon,
  Button,
  Center,
  Group,
  Loader,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useMemo, useState } from "react";
import { Trash } from "tabler-icons-react";
import ajax from "../ajax";

dayjs.extend(customParseFormat);

type Holiday = {
  date: dayjs.Dayjs;
  description: string;
};

type AjaxResultHolidays = {
  date: string;
  description: string;
}[];

const Holidays: React.FC<{}> = () => {
  const [holidays, setHolidays] = useState<Holiday[] | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());

  const [addDate, setAddDate] = useState<Date | null>(null);
  const [addDescription, setAddDescription] = useState<string>("");

  const loadData = () => {
    ajax.get("/holidays").on(200, (res: AjaxResultHolidays) => {
      setHolidays(
        res.map((h) => ({
          date: dayjs(h.date),
          description: h.description,
        }))
      );
    });
  };

  const addHoliday: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (addDate === null || addDescription === "") return;

    let data = {
      date: dayjs(addDate).format("YYYY-MM-DD"),
      description: addDescription,
    };
    ajax.put("/holidays", data).on(204, () => {
      loadData();

      setAddDate(null);
      setAddDescription("");
    });
  };

  const deleteHoliday = (date: string) => {
    ajax.delete(`/holidays/${date}`).on(204, () => {
      showNotification({
        message: "Der Feiertag wurde gelöscht.",
        color: "green",
      });
      loadData();
    });
  };

  const years = useMemo<number[]>(() => {
    if (holidays === null) return [];

    let tmp = new Set(holidays.map((h) => h.date.year()));
    tmp.add(dayjs().year());
    return Array.from(tmp);
  }, [holidays]);

  const filteredHolidays = useMemo<Holiday[] | null>(() => {
    if (holidays === null) return null;

    return holidays
      .filter((h) => h.date.year() === selectedYear)
      .sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1));
  }, [holidays, selectedYear]);

  useEffect(loadData, []);

  if (filteredHolidays === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <SimpleGrid cols={2}>
        <Stack>
          <Select
            label={"Jahr auswählen " + selectedYear}
            value={selectedYear.toString()}
            onChange={(year) => setSelectedYear(year ? +year : 0)}
            data={years.map((val) => val.toString())}
          />

          <Table>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Datum</th>
                <th style={{ width: "70%" }}>Beschreibung</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>

            <tbody>
              {filteredHolidays.length === 0 && (
                <tr>
                  <td colSpan={3}>
                    Es sind noch keine Feiertage für {selectedYear} hinterlegt.
                  </td>
                </tr>
              )}
              {filteredHolidays.map((h) => (
                <tr key={h.date.format("YYYY-MM-DD")}>
                  <td>{h.date.format("DD.MM.YYYY")}</td>
                  <td>{h.description}</td>
                  <td>
                    <ActionIcon
                      size="xs"
                      color="red"
                      onClick={() => deleteHoliday(h.date.format("YYYY-MM-DD"))}
                    >
                      <Trash size={16} />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Stack>

        <Stack>
          <Title order={5}>Feiertag hinzufügen</Title>

          <form onSubmit={addHoliday}>
            <Stack spacing="xs">
              <DatePicker
                locale="de"
                label="Datum"
                placeholder="Auswählen oder eingeben (TT.MM.JJJJ)"
                inputFormat="dddd, DD.MM.YYYY"
                allowFreeInput
                dateParser={(ds) => dayjs(ds, "DD.MM.YYYY").toDate()}
                value={addDate}
                onChange={setAddDate}
              />

              <TextInput
                label="Beschreibung"
                placeholder="Neujahr, ..."
                value={addDescription}
                onChange={(e) => setAddDescription(e.currentTarget.value)}
              />

              <Group position="right">
                <Button
                  type="submit"
                  variant="outline"
                  disabled={addDate === null || addDescription === ""}
                >
                  Hinzufügen
                </Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </SimpleGrid>
    </>
  );
};

export default Holidays;
