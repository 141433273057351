import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Loader,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { CirclePlus, Pencil, Plus, X } from "tabler-icons-react";
import ajax from "../ajax";
import { minutesToString, monthSelectorData } from "../service";

type Person = {
  id: number;
  name: string;
  active: boolean;
  contracts: Contract[];
};

type Contract = {
  year: number;
  month: number;
  quota: number;
};

type AjaxResultPersons = {
  persons: {
    id: number;
    name: string;
    start: number;
  }[];
  contracts: {
    person: number;
    year: number;
    month: number;
    quota: number;
  }[];
};

const getContractDateText = (contract: Contract | undefined): string => {
  if (contract === undefined) {
    return "";
  }

  let months = [
    "",
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  return `${months[contract.month]} ${contract.year}`;
};

const Hiwis: React.FC<{}> = () => {
  const [persons, setPersons] = useState<Person[] | null>(null);
  const [current, setCurrent] = useState<Person | null>(null);
  const [addName, setAddName] = useState<string>("");

  const loadData = () => {
    const contractCompare = (a: Contract, b: Contract): number => {
      return a.year * 12 + a.month - (b.year * 12 + b.month);
    };
    const personCompare = (a: Person, b: Person): number => {
      if (a.active === b.active) {
        return a.name.localeCompare(b.name);
      }

      return +b.active - +a.active;
    };

    ajax.get("/persons").on(200, (res: AjaxResultPersons) => {
      let tmp: Person[] = res.persons
        .map((p) => ({
          id: p.id,
          name: p.name,
          active: res.contracts.some(
            (c) =>
              c.person === p.id &&
              c.month === dayjs().month() + 1 &&
              c.year === dayjs().year()
          ),
          contracts: res.contracts
            .filter((c) => c.person === p.id)
            .map((c) => ({
              year: c.year,
              month: c.month,
              quota: c.quota,
            }))
            .sort(contractCompare),
        }))
        .sort(personCompare);

      setPersons(tmp);
      if (current !== null) {
        setCurrent(tmp.find((p) => p.id === current.id) ?? null);
      }
    });
  };

  const addHiwi: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (addName.length === 0) {
      showNotification({
        message: "Der Name darf nicht leer sein.",
        color: "orange",
      });
    } else if (addName.length > 75) {
      showNotification({
        message: "Der Name ist zu lang.",
        color: "orange",
      });
    } else {
      ajax.put("/persons", { name: addName }).on(204, () => {
        showNotification({
          message: `${addName} wurde erfolgreich hinzugefügt.`,
          color: "green",
        });
        setAddName("");
        loadData();
      });
    }
  };

  const getContractQuota = (contract: Contract | undefined): string => {
    if (contract === undefined) {
      return "";
    }

    return minutesToString(contract.quota);
  };

  const getContract = (
    contracts: Contract[],
    which: "first" | "last" | "current" | number
  ): Contract | undefined => {
    if (which === "first") {
      return contracts.at(0);
    } else if (which === "last") {
      return contracts.at(-1);
    } else if (which === "current") {
      let index = contracts.findIndex(
        (c) => c.month === dayjs().month() + 1 && c.year === dayjs().year()
      );
      if (index < 0) return undefined;
      return contracts.at(index);
    }

    return contracts.at(which);
  };

  const selectorPersons = useMemo<PersonItemProps[]>(() => {
    if (persons === null) return [];

    return persons.map((person) => ({
      value: person.id.toString(),
      label: person.name,
      group: person.active ? "Aktive Hiwis" : "Ehemalige Hiwis",
      contracts: person.contracts,
    }));
  }, [persons]);

  useEffect(loadData, []);

  if (persons === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (current === null) {
    return (
      <Stack>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Erster Vertrag</th>
              <th>Letzter Vertrag</th>
              <th>Aktueller Vertragsumfang</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {persons.map((person) => (
              <tr key={person.id}>
                <td>{person.name}</td>
                <td>
                  {getContractDateText(getContract(person.contracts, "first"))}
                </td>
                <td>
                  {getContractDateText(getContract(person.contracts, "last"))}
                </td>
                <td>
                  {getContractQuota(getContract(person.contracts, "current"))}
                </td>
                <td>
                  <ActionIcon onClick={() => setCurrent(person)}>
                    <Pencil size={18} />
                  </ActionIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Grid>
          <Grid.Col xs={12} md={6} offsetMd={6} xl={4} offsetXl={8}>
            <Card shadow="xl">
              <form onSubmit={addHiwi}>
                <TextInput
                  label="Hiwi hinzufügen"
                  placeholder="Name"
                  value={addName}
                  onChange={(e) => setAddName(e.currentTarget.value)}
                  rightSection={
                    <ActionIcon type="submit" color="gray" size="sm">
                      <Plus size={18} />
                    </ActionIcon>
                  }
                />
              </form>
            </Card>
          </Grid.Col>
        </Grid>
      </Stack>
    );
  }

  return (
    <Stack>
      <SimpleGrid cols={2}>
        <Select
          label="Hiwi auswählen"
          value={current.id.toString()}
          onChange={(id) =>
            setCurrent(
              persons.find((person) => person.id.toString() === id) ?? null
            )
          }
          data={selectorPersons}
          searchable
          itemComponent={PersonSelectItem}
        />

        <Group position="right">
          <ActionIcon color="red" onClick={() => setCurrent(null)}>
            <X />
          </ActionIcon>
        </Group>
      </SimpleGrid>

      <EditForm person={current} reload={loadData} />
    </Stack>
  );
};

export default Hiwis;

interface PersonItemProps extends React.ComponentPropsWithRef<"div"> {
  value: string;
  label: string;
  contracts: Contract[];
}

const PersonSelectItem = forwardRef<HTMLDivElement, PersonItemProps>(
  ({ label, contracts, ...others }: PersonItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>

      <Text size="xs" opacity={0.65}>
        {contracts.length === 0 && "Kein Vertrag"}
        {contracts.length === 1 && getContractDateText(contracts[0])}
        {contracts.length > 1 &&
          `${getContractDateText(contracts.at(0))} - ${getContractDateText(
            contracts.at(-1)
          )}`}
      </Text>
    </div>
  )
);

type EditFormProps = {
  person: Person;
  reload: () => void;
};

const EditForm: React.FC<EditFormProps> = (props) => {
  const [name, setName] = useState<string>(props.person.name);

  const [addStartMonth, setAddStartMonth] = useState<string | null>(
    (dayjs().month() + 1).toString()
  );
  const [addStartYear, setAddStartYear] = useState<string | null>(
    dayjs().year().toString()
  );
  const [addEndMonth, setAddEndMonth] = useState<string | null>(
    (dayjs().month() + 1).toString()
  );
  const [addEndYear, setAddEndYear] = useState<string | null>(
    dayjs().year().toString()
  );
  const [addQuota, setAddQuota] = useState<Date>();

  const sendName: React.FormEventHandler = (e) => {
    e.preventDefault();

    let data = {
      name: name,
    };
    ajax.post(`/persons/name/${props.person.id}`, data).on(204, () => {
      showNotification({
        message: "Der Name wurde geändert.",
        color: "green",
      });
      props.reload();
    });
  };

  const addContract: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (
      addStartMonth === null ||
      addStartYear === null ||
      addEndMonth === null ||
      addEndYear === null ||
      addQuota === undefined
    ) {
      return;
    }

    let data = {
      start: {
        month: +addStartMonth,
        year: +addStartYear,
      },
      end: {
        month: +addEndMonth,
        year: +addEndYear,
      },
      quota: addQuota.getHours() * 60 + addQuota.getMinutes(),
    };

    ajax.post(`/persons/contracts/${props.person.id}`, data).on(204, () => {
      showNotification({
        message: "Die Verträge wurden im entsprechenden Zeitraum angelegt.",
        color: "green",
      });
      props.reload();
    });
  };

  const onPersonChange = (): void => {
    setName(props.person.name);
  };

  const years = useMemo<number[]>(() => {
    let res = [];
    for (let year = 2021; year <= dayjs().year() + 1; year++) {
      res.push(year);
    }
    return res;
  }, []);

  useEffect(onPersonChange, [props.person]);

  return (
    <>
      <Card shadow="xl">
        <Stack>
          <Title order={5}>Hiwi bearbeiten</Title>

          <form onSubmit={sendName}>
            <Stack spacing="xs">
              <TextInput
                label="Name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />

              <Group position="right">
                <Button type="submit" variant="outline">
                  Namen ändern
                </Button>
              </Group>
            </Stack>
          </form>

          <SimpleGrid cols={2}>
            <div>
              <Stack spacing="xs">
                <Title order={5}>Vertragsübersicht</Title>

                <Table>
                  <thead>
                    <tr>
                      <th>Zeitraum</th>
                      <th>Beschäftigung</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...props.person.contracts].reverse().map((contract) => (
                      <tr key={contract.year + contract.month * 12}>
                        <td>{getContractDateText(contract)}</td>
                        <td>
                          {minutesToString(contract.quota)} Stunden / Woche
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Stack>
            </div>

            <div>
              <Stack spacing="xs">
                <Title order={5}>Vertrag hinzufügen</Title>

                <form onSubmit={addContract}>
                  <Stack spacing="xs">
                    <SimpleGrid cols={2}>
                      <Select
                        label="Startmonat"
                        value={addStartMonth}
                        onChange={setAddStartMonth}
                        data={monthSelectorData}
                      />
                      <Select
                        label="Startjahr"
                        value={addStartYear}
                        onChange={setAddStartYear}
                        data={years.map((val) => val.toString())}
                      />
                    </SimpleGrid>

                    <SimpleGrid cols={2}>
                      <Select
                        label="Endmonat"
                        value={addEndMonth}
                        onChange={setAddEndMonth}
                        data={monthSelectorData}
                      />
                      <Select
                        label="Endjahr"
                        value={addEndYear}
                        onChange={setAddEndYear}
                        data={years.map((val) => val.toString())}
                      />
                    </SimpleGrid>

                    <TimeInput
                      label="Wochenstunden"
                      value={addQuota}
                      onChange={setAddQuota}
                    />

                    <Group position="right">
                      <Button
                        type="submit"
                        variant="outline"
                        disabled={addQuota === undefined}
                      >
                        {(addQuota !== undefined &&
                          addQuota.getHours() === 0 &&
                          addQuota.getMinutes() === 0) ||
                          "Vertrag hinzufügen"}
                        {addQuota !== undefined &&
                          addQuota.getHours() === 0 &&
                          addQuota.getMinutes() === 0 &&
                          "Vertrag löschen"}
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Stack>
            </div>
          </SimpleGrid>
        </Stack>
      </Card>
    </>
  );
};
