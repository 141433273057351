import {
  ActionIcon,
  Button,
  Center,
  Group,
  Loader,
  Modal,
  Select,
  Stack,
  Table,
  TextInput,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { CaretLeft, CaretRight } from "tabler-icons-react";
import ajax from "../ajax";
import { minutesToString, monthSelectorData } from "../service";
import "./tableBlock.css";

type DatabaseResult = {
  persons: {
    id: number;
    name: string;
    quota: number;
    year: number;
    month: number;
    saldo_former: number;
  }[];
  entries: {
    person: number;
    date: string;
    value: number;
  }[];
  holidays: {
    date: string;
    description: string;
  }[];
};

type Person = {
  id: number;
  name: string;
  quota: number;
  saldoFormer: number;
  entries: Map<string, number>;
  sumOfEntries: number;
  generalHoliday: number;
  specialHoliday: number;
};

type HolidayMap = Map<string, string>;

type CurrentEntry = {
  date: dayjs.Dayjs;
  person: {
    id: number;
    name: string;
  };
};

const Timetable: React.FC<{}> = () => {
  const [year, setYear] = useState<number>(dayjs().year());
  const [month, setMonth] = useState<number>(dayjs().month() + 1);

  const [persons, setPersons] = useState<Person[] | null>(null);
  const [holidays, setHolidays] = useState<HolidayMap | null>(null);

  const [currentEntry, setCurrentEntry] = useState<CurrentEntry | null>(null);

  const loadData = (): void => {
    if (year === null || month === null) return;

    ajax.get(`/timetable/${year}/${month}`).on(200, (res: DatabaseResult) => {
      setPersons(
        res.persons.map((p) => ({
          id: p.id,
          name: p.name,
          quota: p.quota,
          saldoFormer: p.saldo_former,
          entries: new Map(
            res.entries
              .filter((e) => e.person === p.id)
              .map((e) => [dayjs(e.date).format("YYYY-MM-DD"), e.value])
          ),
          sumOfEntries: res.entries
            .filter((e) => e.person === p.id)
            .map((e) => e.value)
            .reduce((c, r) => c + r, 0),
          generalHoliday: Math.round(p.quota / 3),
          specialHoliday: Math.round((res.holidays.length * p.quota) / 5),
        }))
      );

      setHolidays(
        new Map(
          res.holidays.map((row) => [
            dayjs(row.date).format("YYYY-MM-DD"),
            row.description,
          ])
        )
      );
    });
  };

  const moveMonth = (direction: number): void => {
    let current = year * 12 + month - 1;
    current += direction;

    setYear(Math.floor(current / 12));
    setMonth((current % 12) + 1);
  };

  const years = useMemo<string[]>(() => {
    let res: string[] = [];
    for (let y = 2021; y <= dayjs().year() + 1; y++) {
      res.push(y.toString());
    }
    return res;
  }, []);

  const personCellStyle = useMemo<React.CSSProperties>(() => {
    if (persons === null) {
      return {};
    }

    return {
      width: 80.0 / persons.length + "%",
      textAlign: "right",
    };
  }, [persons]);

  const days = useMemo<dayjs.Dayjs[]>(() => {
    if (year === null || month === null) return [];
    let y = +year;
    let m = +month - 1;

    let res: dayjs.Dayjs[] = [];

    for (
      let cursor = dayjs().year(y).month(m).date(1);
      cursor.month() === m;
      cursor = cursor.date(cursor.date() + 1)
    ) {
      res.push(cursor);
    }

    return res;
  }, [year, month]);

  useEffect(loadData, [year, month]);

  if (persons === null || holidays === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <EditModal
        data={currentEntry}
        onHide={() => setCurrentEntry(null)}
        reload={loadData}
      />

      <Stack>
        <Group>
          <ActionIcon
            size="lg"
            style={{ marginTop: "auto" }}
            onClick={() => moveMonth(-1)}
          >
            <CaretLeft />
          </ActionIcon>

          <Select
            label="Monat"
            value={month.toString()}
            onChange={(val) => setMonth(val ? +val : dayjs().month() + 1)}
            data={monthSelectorData}
            style={{ flexGrow: 1 }}
          />

          <Select
            label="Jahr"
            value={year.toString()}
            onChange={(val) => setYear(val ? +val : dayjs().year())}
            data={years}
            style={{ flexGrow: 1 }}
          />

          <ActionIcon
            size="lg"
            style={{ marginTop: "auto" }}
            onClick={() => moveMonth(1)}
          >
            <CaretRight />
          </ActionIcon>
        </Group>

        <Table
          highlightOnHover
          withBorder
          withColumnBorders
          mb="xl"
          style={{ borderBottomColor: "black" }}
        >
          <thead>
            <tr>
              <th scope="col" colSpan={2} style={{ width: "20%" }} />

              {persons.map((p) => (
                <th key={p.id} scope="col" style={personCellStyle}>
                  {p.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {days.map((d) => (
              <TimetableRow
                key={d.date()}
                timestamp={d}
                persons={persons}
                holidays={holidays}
                onSelect={setCurrentEntry}
              />
            ))}

            <tr className="table-block-start">
              <td colSpan={2} style={{ borderTopColor: "black" }}>
                Ist-Stunden aktuell
              </td>
              {persons.map((p) => (
                <td key={p.id} style={{ borderTopColor: "black" }}>
                  {minutesToString(p.sumOfEntries)} h
                </td>
              ))}
            </tr>

            <tr>
              <td colSpan={2}>Urlaub</td>
              {persons.map((p) => (
                <td key={p.id}>{minutesToString(p.generalHoliday)} h</td>
              ))}
            </tr>

            <tr>
              <td colSpan={2}>Feiertage</td>
              {persons.map((p) => (
                <td key={p.id}>{minutesToString(p.specialHoliday)} h</td>
              ))}
            </tr>

            <tr>
              <td colSpan={2} style={{ borderTopColor: "black" }}>
                Zwischensumme
              </td>
              {persons.map((p) => (
                <td key={p.id} style={{ borderTopColor: "black" }}>
                  {minutesToString(
                    Math.round(
                      p.sumOfEntries + p.generalHoliday + p.specialHoliday
                    )
                  )}{" "}
                  h
                </td>
              ))}
            </tr>

            <tr>
              <td colSpan={2}>Sollstunden</td>
              {persons.map((p) => (
                <td key={p.id}>
                  {minutesToString(Math.round(p.quota * 4.348))} h
                </td>
              ))}
            </tr>

            <tr>
              <td colSpan={2}>Überstunden/Fehlstunden</td>
              {persons.map((p) => (
                <td key={p.id}>
                  {minutesToString(
                    Math.round(
                      p.sumOfEntries +
                        p.generalHoliday +
                        p.specialHoliday -
                        p.quota * 4.348
                    )
                  )}{" "}
                  h
                </td>
              ))}
            </tr>

            <tr>
              <td colSpan={2} style={{ borderTopColor: "black" }}>
                Übertrag Vormonat
              </td>
              {persons.map((p) => (
                <td key={p.id} style={{ borderTopColor: "black" }}>
                  {minutesToString(p.saldoFormer)} h
                </td>
              ))}
            </tr>

            <tr>
              <td colSpan={2}>Saldo</td>
              {persons.map((p) => (
                <td key={p.id}>
                  {minutesToString(
                    Math.round(
                      p.sumOfEntries +
                        p.generalHoliday +
                        p.specialHoliday -
                        p.quota * 4.348 +
                        p.saldoFormer
                    )
                  )}{" "}
                  h
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Stack>
    </>
  );
};

export default Timetable;

type TimetableRowProps = {
  timestamp: dayjs.Dayjs;
  persons: Person[];
  holidays: HolidayMap;
  onSelect: (ce: CurrentEntry) => void;
};

const TimetableRow: React.FC<TimetableRowProps> = (props) => {
  const dateString = props.timestamp.format("YYYY-MM-DD");

  const holidayName = useMemo<string | null>(() => {
    return props.holidays.get(dateString) ?? null;
  }, [props.holidays, dateString]);

  const rowStyles: React.CSSProperties = {};
  if (
    props.holidays.has(dateString) ||
    props.timestamp.day() === 0 ||
    props.timestamp.day() === 6
  )
    rowStyles.backgroundColor = "#cfd1d2";

  const headerCellStylesLeft: React.CSSProperties = {
    width: "10%",
    borderRight: 0,
  };
  const headerCellStylesRight: React.CSSProperties = {
    width: "10%",
    borderLeft: 0,
  };

  const contentCellStyles: React.CSSProperties = {
    width: 80.0 / props.persons.length + "%",
    textAlign: "right",
    cursor: "pointer",
  };

  return (
    <tr style={rowStyles}>
      <td style={headerCellStylesLeft}>
        {props.timestamp.format("DD.MM.YYYY")}
      </td>
      <td style={headerCellStylesRight}>
        {holidayName ?? props.timestamp.format("dddd")}
      </td>

      {props.persons.map((p) => (
        <td
          key={p.id}
          style={contentCellStyles}
          onClick={() =>
            props.onSelect({
              date: props.timestamp,
              person: {
                id: p.id,
                name: p.name,
              },
            })
          }
        >
          {p.entries.has(dateString)
            ? minutesToString(p.entries.get(dateString) ?? 0) + " h"
            : ""}
        </td>
      ))}
    </tr>
  );
};

type EditModalProps = {
  data: CurrentEntry | null;
  onHide: () => void;
  reload: () => void;
};

const EditModal: React.FC<EditModalProps> = (props) => {
  const [value, setValue] = useState<Date | null>(null);

  const save: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (value === null || props.data === null) return;

    ajax
      .put("/entry", {
        person: props.data.person.id,
        date: props.data.date.format("YYYY-MM-DD"),
        value: value.getHours() * 60 + value.getMinutes(),
      })
      .on(204, () => {
        props.reload();
        props.onHide();

        setValue(null);
      });
  };

  return (
    <Modal
      opened={props.data !== null}
      onClose={props.onHide}
      title="Stunden eintragen"
    >
      <form onSubmit={save}>
        <Stack spacing="xs">
          <TextInput label="Name" value={props.data?.person.name} readOnly />
          <TextInput
            label="Datum"
            value={props.data?.date.format("dddd, DD.MM.YYYY")}
            readOnly
          />

          <TimeInput
            label="Wie viele Stunden hast du gearbeitet?"
            value={value}
            onChange={setValue}
          />

          <Group position="right">
            <Button
              type="button"
              variant="outline"
              color="gray"
              onClick={props.onHide}
            >
              Abbrechen
            </Button>
            <Button type="submit" variant="outline">
              Speichern
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
