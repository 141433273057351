import { Container } from "@mantine/core";
import {
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Error404 } from "./404";
import ajax from "./ajax";
import Settings from "./components/settings";
import Timetable from "./view/timetable";

// works for all pages
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekdays: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
});

const App: React.FC<{}> = () => {
  ajax.on("*", () => {
    showNotification({
      message: "Die angeforderte Aktion konnte nicht ausgeführt werden.",
      color: "red",
    });
  });

  return (
    <React.StrictMode>
      <NotificationsProvider>
        <Container mt="xl" size="xl">
          <BrowserRouter>
            <Routes>
              <Route
                path="/h2sk34ile6rd86jytbd14adbs/view"
                element={<Timetable />}
              />
              <Route
                path="/h2sk34ile6rd86jytbd14adbs/settings"
                element={<Settings />}
              />

              <Route path="*" element={<Error404 />} />
            </Routes>
          </BrowserRouter>
        </Container>
      </NotificationsProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
