import { SelectItem } from "@mantine/core";

export const monthSelectorData: SelectItem[] = [
  { value: "1", label: "Januar" },
  { value: "2", label: "Februar" },
  { value: "3", label: "März" },
  { value: "4", label: "April" },
  { value: "5", label: "Mai" },
  { value: "6", label: "Juni" },
  { value: "7", label: "Juli" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "Oktober" },
  { value: "11", label: "November" },
  { value: "12", label: "Dezember" },
];

export const days = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
  0: "Sonntag",
};

export const minutesToString = (value: number): string => {
  let res: string = value < 0 ? "-" : "";
  value = Math.abs(value);

  res += Math.floor(value / 60) + ":";
  if (value % 60 < 10) res += "0";
  res += value % 60;

  return res;
};
