import { Tabs } from "@mantine/core";
import Hiwis from "./hiwis";
import Holidays from "./holiday";

const Settings: React.FC<{}> = () => {
  return (
    <Tabs defaultValue="hiwis">
      <Tabs.List>
        <Tabs.Tab value="hiwis">Hiwis</Tabs.Tab>
        <Tabs.Tab value="holidays">Feiertage</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="hiwis" pt="xs">
        <Hiwis />
      </Tabs.Panel>

      <Tabs.Panel value="holidays" pt="xs">
        <Holidays />
      </Tabs.Panel>
    </Tabs>
  );
};

export default Settings;
