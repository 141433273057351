import axios from "axios";

type StatusCode = number | number[] | "*";

type CallbackFunction = (data: any) => void;

type CallbackMap = Map<StatusCode, CallbackFunction>;

type AjaxRequest = {
  on: (status: StatusCode, cb: CallbackFunction) => AjaxRequest;
};

const ajax = (() => {
  const AXIOS_OPTIONS = {
    withCredentials: true,
    headers: {
      Authorization: "h2sk34ile6rd86jytbd14adbs",
    },
  };

  const globalListeners: CallbackMap = new Map();

  const callMatchingListener = (
    localListeners: CallbackMap,
    status: number,
    data: any
  ): void => {
    let listener = localListeners.get(status);
    if (listener !== undefined) {
      listener(data);
      return;
    }

    listener = globalListeners.get(status);
    if (listener !== undefined) {
      listener(data);
      return;
    }

    listener = localListeners.get("*");
    if (listener !== undefined) {
      listener(data);
      return;
    }

    listener = globalListeners.get("*");
    if (listener !== undefined) {
      listener(data);
      return;
    }

    throw new Error("There is no callback defined for status code " + status);
  };

  const setListenerHelper = (
    listeners: CallbackMap,
    status: StatusCode,
    cb: CallbackFunction
  ): void => {
    if (Array.isArray(status)) {
      status.forEach((s) => {
        listeners.set(s, cb);
      });
    } else {
      listeners.set(status, cb);
    }
  };

  const setListener = (status: StatusCode, cb: CallbackFunction): void => {
    setListenerHelper(globalListeners, status, cb);
  };

  const get = (endpoint: string): AjaxRequest => {
    let localListeners: CallbackMap = new Map();

    let setListener = (
      status: StatusCode,
      cb: CallbackFunction
    ): AjaxRequest => {
      setListenerHelper(localListeners, status, cb);
      return {
        on: setListener,
      };
    };

    axios
      .get(process.env.REACT_APP_API_BASE + endpoint, AXIOS_OPTIONS)
      .then((res) => {
        callMatchingListener(localListeners, res.status, res.data);
      })
      .catch((err) => {
        callMatchingListener(
          localListeners,
          err.response.status,
          err.response.data
        );
      });

    return {
      on: setListener,
    };
  };

  const post = (endpoint: string, data: any): AjaxRequest => {
    let localListeners: CallbackMap = new Map();

    let setListener = (
      status: StatusCode,
      cb: CallbackFunction
    ): AjaxRequest => {
      setListenerHelper(localListeners, status, cb);
      return {
        on: setListener,
      };
    };

    axios
      .post(process.env.REACT_APP_API_BASE + endpoint, data, AXIOS_OPTIONS)
      .then((res) => {
        callMatchingListener(localListeners, res.status, res.data);
      })
      .catch((err) => {
        callMatchingListener(
          localListeners,
          err.response.status,
          err.response.data
        );
      });

    return {
      on: setListener,
    };
  };

  const put = (endpoint: string, data: any): AjaxRequest => {
    let localListeners: CallbackMap = new Map();

    let setListener = (
      status: StatusCode,
      cb: CallbackFunction
    ): AjaxRequest => {
      setListenerHelper(localListeners, status, cb);
      return {
        on: setListener,
      };
    };

    axios
      .put(process.env.REACT_APP_API_BASE + endpoint, data, AXIOS_OPTIONS)
      .then((res) => {
        callMatchingListener(localListeners, res.status, res.data);
      })
      .catch((err) => {
        callMatchingListener(
          localListeners,
          err.response.status,
          err.response.data
        );
      });

    return {
      on: setListener,
    };
  };

  const del = (endpoint: string): AjaxRequest => {
    let localListeners: CallbackMap = new Map();

    let setListener = (
      status: StatusCode,
      cb: CallbackFunction
    ): AjaxRequest => {
      setListenerHelper(localListeners, status, cb);
      return {
        on: setListener,
      };
    };

    axios
      .delete(process.env.REACT_APP_API_BASE + endpoint, AXIOS_OPTIONS)
      .then((res) => {
        callMatchingListener(localListeners, res.status, res.data);
      })
      .catch((err) => {
        callMatchingListener(
          localListeners,
          err.response.status,
          err.response.data
        );
      });

    return {
      on: setListener,
    };
  };

  return {
    on: setListener,
    get: get,
    post: post,
    put: put,
    delete: del,
  };
})();

export default ajax;
